(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pmf.controller:PmfCtrl
   *
   * @description
   *
   */
  angular
    .module('pmf')
    .controller('PmfCtrl', PmfCtrl);

  function PmfCtrl($stateParams, Pmf, Locations) {
    var vm = this;
    vm.ctrlName = 'PmfCtrl';
	vm.location = $stateParams.location;
	if (vm.location){
		Locations.find({"filter":{"where":{"locationCode":vm.location}}}).$promise.then(
			function(results){
				vm.Location = results[0]
			}
		);
	}
	Pmf.find({"filter":{"include":["Locations"]}}).$promise.then(
		function(results){
			vm.pmflist = results;
			//console.log(results);
	});
	
  }
}());
